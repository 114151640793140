import vueFormGenerator from 'vue-form-generator';
import { formatter } from '@/services/helpers/formatter';
import serviceEnums from '@/services/service/enums.service';

export const model = {
	Id: null,
	ObjectId: null,
	ObjectUrl: null,
	ObjectLink: {
		Name: null,
		Href: null,
	},
	Type: null,
	Datetime: null,
	Name: null,
	Email: null,
	Phone: null,
	QuantityAdults: null,
	QuantityChildrens: null,
	ChildrenAge: null,
	DateStart: null,
	DateEnd: null,
	Note: null,
	OreservationLanguage: null,
	FeedbackEmailSent: null,
	FeedbackEmailSentCount: null,
	ContactId: null,
	Createdby: null,
	ReviewHistory: [],
	VisitorReviewHistory: [],
};

export const fields = [
	{
		fields: [
			{
				type: 'objectlink',
				icon: 'eye',
				model: 'ObjectLink',
				i18n: {
					label: 'forobject2936',
				},
			},
			{
				type: 'label',
				inputType: 'text',
				model: 'Type',
				i18n: {
					label: 'reservationtype',
				},
			},
			{
				type: 'label',
				model: 'Datetime',
				i18n: {
					label: 'created:',
				},
				get: function(model) {
					return model && model.Datetime ? formatter.dateTimeToString(model.Datetime) : '';
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'Name',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'name:',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'Email',
				validator: vueFormGenerator.validators.email,
				i18n: {
					label: 'e-mail:',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'Phone',
				i18n: {
					label: 'phone:',
				},
			},
		],
	},
	{
		styleClasses: 'inline-input-group-date',
		fields: [
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'DateStart',
				autoselect: true,
				required: true,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'arrive2938',
				},
				styleClasses: 'first-input',
			},
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'DateEnd',
				autoselect: true,
				required: true,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'depart2939',
				},
				styleClasses: 'second-input',
			},
		],
	},
	{
		fields: [
			{
				type: 'input',
				inputType: 'number',
				model: 'QuantityAdults',
				validator: vueFormGenerator.validators.number,
				i18n: {
					label: 'numberofadults',
				},
				styleClasses: 'eighther-width',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'QuantityChildrens',
				i18n: {
					label: 'numberofchildre',
				},
				styleClasses: 'eighther-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ChildrenAge',
				i18n: {
					label: 'numberofadults2940',
				},
				styleClasses: 'quarter-width',
			},
			{
				type: 'textArea',
				model: 'Note',
				min: 1,
				rows: 6,
				i18n: {
					label: 'text',
				},
			},
			{
				type: 'reviewHistory',
				visitorFeedback: false,
				model: 'ReviewHistory',
				i18n: {
					label: 'feedbackfromlan',
				},
				styleClasses: 'review-history',
				visible(model) {
					return model && model.ReviewHistory.length !== 0;
				},
			},
			{
				type: 'reviewHistory',
				visitorFeedback: true,
				model: 'VisitorReviewHistory',
				i18n: {
					label: 'feedbackfromvis',
				},
				styleClasses: 'review-history',
				visible(model) {
					return model && model.VisitorReviewHistory.length !== 0;
				},
			},
		],
	},
	{
		fields: [
			{
				type: 'label',
				model: 'Createdby',
				i18n: {
					label: 'whocreated',
				},
				styleClasses: 'quarter-width',
				get: function(model) {
					return serviceEnums.getEnumItemText('reservationCreatedBy', model.Createdby);
				},
			},
			{
				type: 'select',
				model: 'OreservationLanguage',
				validator: vueFormGenerator.validators.string,
				values: [],
				selectOptions: {
					value: 'LanguageCode',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'languange:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'textArea',
				model: 'OwnerNote',
				min: 1,
				rows: 6,
				i18n: {
					label: 'potentialguest',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'TotalPrice',
				i18n: {
					label: 'landlord2944',
				},
				styleClasses: 'quarter-width',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'DepositPrice',
				i18n: {
					label: 'depositperstay2945',
				},
				styleClasses: 'quarter-width',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'SurchargePrice',
				i18n: {
					label: 'surchargeonsite2946',
				},
				styleClasses: 'quarter-width',
			},

			/*
			{
				type: 'select',
				model: 'Language',
				values: [],
				selectOptions: {
					value: 'LanguageCode',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'OreservationLanguage - slovko',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'FeedbackEmailSent',
				showtime: true,
				autoselect: true,
				i18n: {
					label: 'OreservationFeedbackEmailSent - slovko',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'FeedbackEmailSentCount',
				i18n: {
					label: 'OreservationFeedbackEmailSentCount - slovko',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'ContactId',
				i18n: {
					label: 'OreservationContactId - slovko',
				},
			},
			*/
		],
	},
];
