<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		:showActionDelete="!!detailEntityId"
		tooltipMsg="manualforreserv3305"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceEnums from '@/services/service/enums.service';
import serviceCommon from '@/services/service/common.service';

import { model, fields } from './object-reservation.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'ObjectReservation',
			routeParam: 'objectReservationId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailofreserva');
			const id = this.$t('id');
			const idNum = this.model.Id;
			return `${title} ${id} ${idNum}`;
		},
	},

	methods: {
		async loadResources() {
			// const [territoryEnum, districtEnum] = await Promise.all([]);

			this.updateSchemaFields({
				OreservationLanguage: { values: this.$store.state.country.data },
				Type: { values: serviceEnums.getEnumForDropdown('objectReservationType') },
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.Id, this.$modal, this.deleteModel);
		},

		transformDtoToModel(dtoModel) {
			dtoModel.DateRange = {
				startDate: dtoModel.OreviewResidenceFromDatetime,
				endDate: dtoModel.OreviewResidenceToDatetime,
			};

			dtoModel.ObjectLink = {
				Name: dtoModel.ObjectName + ' ' + this.$i18n.t('id') + ' ' + dtoModel.ObjectId,
				Href: dtoModel.ObjectUrl,
			};

			return dtoModel;
		},
	},
};
</script>

<style lang="scss">
.review-history {
	margin-bottom: 0px;
}
.inline-input-group-date {
	width: 68%;

	.first-input {
		width: 50%;

		> label {
			width: 56% !important;
		}

		.field-wrap {
			width: inherit;
			> .datepicker-wrapper {
				width: 100%;
			}
		}
	}
	.second-input {
		padding-left: 10px;
		width: 50%;

		> label {
			width: auto;
		}
		.field-wrap {
			width: inherit;
			> .datepicker-wrapper {
				width: 100%;
			}
		}
	}
}
</style>
